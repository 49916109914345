import { LOADING_SHOW, LOADING_HIDE } from '~/store/actions/types';

export const INITIAL_STATE = {
  data: null,
  loading: false,
  readOnly: true,
  error: { message: '', hasError: false },
};

const LoadingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_SHOW:
      return {
        ...state,
        loading: true,
      };
    case LOADING_HIDE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default LoadingReducer;
