import React, { lazy, Suspense } from 'react';
import { Router as BrowserRouter, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createBrowserHistory } from 'history';
import GlobalStyles from './styles/global';
import Loading from './components/Loading';
import PrivateRoute from './components/PrivateRoute';
import { makeStyles } from '@material-ui/core/styles';

import AppHeader from './components/AppHeader';
import AppMenu from './components/AppMenu';
import Copyright from './components/Copyright';
import Spinner from './components/Spinner';
import CustomSnackBar from './components/StateSnackbar';

import {
  createMuiTheme,
  responsiveFontSizes,
  MuiThemeProvider
} from "@material-ui/core";

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const SignIn = lazy(() => import('./pages/SignIn'));
const Home = lazy(() => import('./pages/Home'));
const Consumidor = lazy(() => import('./pages/Consumidor'));
const RelatorioEmpresa = lazy(() => import('./pages/Administrador/Empresa/Relatorio'));
const Empresa = lazy(() => import('./pages/Empresa'));
const EsqueciSenha = lazy(() => import('./pages/EsqueciSenha'));
const RecuperarSenha = lazy(() => import('./pages/RecuperarSenha'));
const AlterarSenha = lazy(() => import('./pages/AlterarSenha'));
const Reclamacao = lazy(() => import('./pages/Reclamacao'));
const Error404 = lazy(() => import('./pages/404'));
const EmpresaLinkAtivacaoEnviado = lazy(() => import('./pages/Empresa/EmpresaLinkAtivacaoEnviado'));
const EmpresaCadastroAtivado = lazy(() => import('./pages/Empresa/CadastroAtivado'));
const RelatorioTelefone = lazy(() => import('./pages/Administrador/Consumidor/RelatorioTelefone'));
const RelatorioConsumidor = lazy(() => import('./pages/Administrador/Consumidor/Relatorio'));
const ManterConsumidor = lazy(() => import('./pages/Administrador/Consumidor/ManterConsumidor'));
const ReclamacaoConsumidor = lazy(() => import('./pages/Administrador/Consumidor/ReclamacaoConsumidor'));
const AlterarConsumidor = lazy(() => import('./pages/Administrador/Consumidor/AlterarConsumidor'));
const HomeAdministrador = lazy(() => import('./pages/Administrador'));
const GestaoReclamacao = lazy(() => import('./pages/Administrador/Reclamacao/GestaoReclamacao'));
const AlterarReclamacao = lazy(() => import('./pages/Administrador/Reclamacao/AlterarReclamacao'));
const RelatorioEmpresaReclamacao = lazy(() => import('./pages/Administrador/Empresa/RelatorioEmpresaReclamacao'));
const GestaoEmpresa = lazy(() => import('./pages/Administrador/Empresa/GestaoEmpresa'));
const AlterarEmpresa = lazy(() => import('./pages/Administrador/Empresa/AlterarEmpresa'));
const ReclamacoesEmpresa = lazy(() => import('./pages/Administrador/Empresa/ReclamacoesEmpresa'));
const SignInAdm = lazy(() => import('./pages/Administrador/SignIn'));
const browserHistory = createBrowserHistory();
const RelatorioLogReclamacao = lazy(() => import('./pages/Administrador/Reclamacao/RelatorioLog'));

const Routes = () => {
  const { loading } = useSelector(store => store.loadingReducer);
  const { isAuthenticated } = useSelector(store => store.authReducer);
  const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
    hide: {
      display: 'none',
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      minHeight: 'calc(100vh - 37px)',
      flexGrow: 1,
      padding: '0 16px 0 16px',
      [theme.breakpoints.up('md')]: {
        padding: isAuthenticated && '0 16px 0 75px',
      },
    },
  }));

  const classes = useStyles();

  return (
    <BrowserRouter history={browserHistory}>
      <Spinner loading={loading}>
        <AppHeader />
        <AppMenu />
       <MuiThemeProvider theme={theme}>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/login" component={SignIn} />
              <Route path="/login-adm" component={SignInAdm} />
              <Route path="/esqueci-minha-senha" component={EsqueciSenha} />
              <Route path="/recuperar-senha/:token" component={RecuperarSenha} />
              <Route path="/alterar-senha" component={AlterarSenha} />
              <Route exact path="/consumidor/reclamacao" component={Reclamacao} />
              <Route exact path="/consumidor" component={Consumidor} />
              <Route path="/consumidor/:id" component={Consumidor} />
              <Route exact path="/empresa/linkativacaoenviado" component={EmpresaLinkAtivacaoEnviado} />
              <Route exact path="/empresa/confirmacadastro/:cnpj/:token" component={EmpresaCadastroAtivado} />
              <Route exact path="/empresa" component={Empresa} />
              <PrivateRoute exact path="/administrador/" perfil="ADMINISTRADOR" component={HomeAdministrador} />
              <PrivateRoute
                exact
                path="/administrador/consumidor/numerosbloqueados"
                perfil="ADMINISTRADOR"
                component={RelatorioTelefone}
              />
              <PrivateRoute
                exact
                path="/administrador/consumidor/relatorio"
                perfil="ADMINISTRADOR"
                component={RelatorioConsumidor}
              />
              <PrivateRoute
                exact
                path="/administrador/consumidor/manterconsumidor"
                perfil="ADMINISTRADOR"
                component={ManterConsumidor}
              />
              <PrivateRoute
                exact
                path="/administrador/consumidor/reclamacaoconsumidor"
                perfil="ADMINISTRADOR"
                component={ReclamacaoConsumidor}
              />
              <PrivateRoute
                exact
                path="/administrador/consumidor/alterarconsumidor"
                perfil="ADMINISTRADOR"
                component={AlterarConsumidor}
              />
              <PrivateRoute
                exact
                path="/administrador/empresa/relatorio"
                perfil="ADMINISTRADOR"
                component={RelatorioEmpresa}
              />
              <PrivateRoute
                exact
                path="/administrador/empresa/gestao"
                perfil="ADMINISTRADOR"
                component={GestaoEmpresa}
              />
              <PrivateRoute
                exact
                path="/administrador/empresa/alterar"
                perfil="ADMINISTRADOR"
                component={AlterarEmpresa}
              />
              <PrivateRoute
                exact
                path="/administrador/empresa/reclamacoes"
                perfil="ADMINISTRADOR"
                component={ReclamacoesEmpresa}
              />
              <PrivateRoute
                exact
                path="/administrador/reclamacao/gestao"
                perfil="ADMINISTRADOR"
                component={GestaoReclamacao}
              />
              <PrivateRoute
                exact
                path="/administrador/reclamacao/alterar"
                perfil="ADMINISTRADOR"
                component={AlterarReclamacao}
              />
              <PrivateRoute
                exact
                path="/administrador/empresa/relatorioempresareclamacao"
                perfil="ADMINISTRADOR"
                component={RelatorioEmpresaReclamacao}
              />
              <PrivateRoute
                exact
                path="/administrador/reclamacao/relatoriolog"
                perfil="ADMINISTRADOR"
                component={RelatorioLogReclamacao}
              />
              <PrivateRoute path="/home" component={Home} />
              <Route path="*" component={Error404} />
            </Switch>
          </Suspense>
        </main>
        </MuiThemeProvider>
        <Copyright />
        <GlobalStyles />
      </Spinner>
      <CustomSnackBar />
    </BrowserRouter>
  );
};
export default Routes;
