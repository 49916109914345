import {
  AUTH_ERROR,
  AUTH_LOGOUT,
  AUTH_LOGIN,
  AUTH_ESQUECI_SENHA,
  AUTH_LOGIN_START,
  AUTH_ALTERAR_SENHA_ESQUECIDA,
  AUTH_ALTERAR_SENHA_CLEAR,
  AUTH_ALTERAR_SENHA,
  AUTH_ALTERAR_SENHA_ERROR,
  AUTH_MESSAGE_CLEAR,
} from '../../store/actions/types';

export const INITIAL_STATE = {
  nome: null,
  login: null,
  email: null,
  authority: null,
  token: null,
  isAuthenticated: false,
  message: null,
  loading: false,
  recover: false,
  dataLogin: null,
};

const AuthReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_LOGIN_START:
      return { ...state, ...action.payload };
    case AUTH_LOGIN:
      return { ...state, ...action.payload, isAuthenticated: true };
    case AUTH_ERROR:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: false,
      };
    case AUTH_MESSAGE_CLEAR:
      return {
        ...state,
        ...action.payload,
        message: null,
        loading: false,
        recover: false,
      };
    case AUTH_LOGOUT:
      return {
        ...state,
        nome: null,
        login: null,
        email: null,
        authority: null,
        token: null,
        isAuthenticated: false,
        message: null,
      };
    case AUTH_ESQUECI_SENHA:
      return {
        ...state,
        nome: null,
        login: null,
        email: null,
        authority: null,
        token: null,
        isAuthenticated: false,
        ...action.payload,
      };
    case AUTH_ALTERAR_SENHA_ESQUECIDA:
      return {
        ...state,
        nome: null,
        login: null,
        email: null,
        authority: null,
        token: null,
        isAuthenticated: false,
        ...action.payload,
      };
    case AUTH_ALTERAR_SENHA:
      return {
        ...state,
        ...action.payload,
      };
    case AUTH_ALTERAR_SENHA_CLEAR:
      return {
        ...state,
        ...action.payload,
        message: null,
        loading: false,
        recover: false,
      };
    case AUTH_ALTERAR_SENHA_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default AuthReducer;
