import { MENU_OPEN, MENU_CLOSE } from '../actions/types';

export const INITIAL_STATE = {
  isOpen: false,
};

const MenuReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MENU_OPEN:
      return {
        ...state,
        isOpen: true,
      };
    case MENU_CLOSE:
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
};

export default MenuReducer;
