import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { authority } = useSelector(store => store.authReducer);

  return (
    <Route
      {...rest}
      render={props =>
        authority === rest.perfil ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: `/${authority.toLowerCase()}`, state: { from: props.location } }} />
        )
      }
    />
  );
};

export default PrivateRoute;
