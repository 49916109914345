import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PefilRoute from './PerfilRoute';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useSelector(store => store.authReducer);
  return (
    <Route
      {...rest}
      render={props => {
        return isAuthenticated ? (
          <PefilRoute {...props} perfil={rest.perfil} component={Component} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        );
      }}
    />
  );
};

export default PrivateRoute;
