export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGIN_START = 'AUTH_LOGIN_START';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_ESQUECI_SENHA = 'AUTH_ESQUECI_SENHA';
export const AUTH_ALTERAR_SENHA_ESQUECIDA = 'AUTH_ALTERAR_SENHA_ESQUECIDA';
export const AUTH_ALTERAR_SENHA = 'AUTH_ALTERAR_SENHA';
export const AUTH_ALTERAR_SENHA_START = 'AUTH_ALTERAR_SENHA_START';
export const AUTH_ALTERAR_SENHA_ERROR = 'AUTH_ALTERAR_SENHA_ERROR';
export const AUTH_ALTERAR_SENHA_CLEAR = 'AUTH_ALTERAR_SENHA_CLEAR';
export const AUTH_MESSAGE_CLEAR = 'AUTH_MESSAGE_CLEAR';
export const AUTH_SIGNUP = 'AUTH_SIGNUP';

export const USERS_FETCH_ALL = 'USERS_FETCH_ALL';

export const EMPRESA_READ_ONLY = 'READ_ONLY_EMPRESA';
export const EMPRESA_LOADING_SHOW = 'EMPRESA_LOADING_SHOW';
export const EMPRESA_FETCH_SUCCESS = 'EMPRESA_FETCH_SUCCESS';
export const EMPRESA_FETCH_ERROR = 'EMPRESA_FETCH_ERROR';
export const EMPRESA_LOADING_HIDE = 'EMPRESA_LOADING_HIDE';

export const CONSUMIDOR_FETCH = 'CONSUMIDOR_FETCH';
export const CONSUMIDOR_FETCH_SUCCESS = 'CONSUMIDOR_FETCH_SUCCESS';
export const CONSUMIDOR_FETCH_ERROR = 'CONSUMIDOR_FETCH_ERROR';

export const CONSUMIDOR_SIGNED = 'CONSUMIDOR_SIGNED';
export const CONSUMIDOR_SIGNED_SUCCESS = 'CONSUMIDOR_SIGNED_SUCCESS';
export const CONSUMIDOR_SIGNED_ERROR = 'CONSUMIDOR_SIGNED_ERROR';

export const CONSUMIDOR_SUBMIT = 'CONSUMIDOR_SUBMIT';
export const CONSUMIDOR_SUBMIT_SUCCESS = 'CONSUMIDOR_SUBMIT_SUCCESS';
export const CONSUMIDOR_SUBMIT_ERROR = 'CONSUMIDOR_SUBMIT_ERROR';

export const CONSUMIDOR_CADASTRO = 'CONSUMIDOR_CADASTRO';
export const CONSUMIDOR_CADASTRO_ERROR = 'CONSUMIDOR_CADASTRO_ERROR';

export const CONSUMIDOR_DISABLE = 'CONSUMIDOR_DISABLE';
export const CONSUMIDOR_DISABLE_SUCCESS = 'CONSUMIDOR_DISABLE_SUCCESS';
export const CONSUMIDOR_DISABLE_ERROR = 'CONSUMIDOR_DISABLE_ERROR';

export const CONSUMIDOR_ENABLE = 'CONSUMIDOR_ENABLE';
export const CONSUMIDOR_ENABLE_SUCCESS = 'CONSUMIDOR_ENABLE_SUCCESS';
export const CONSUMIDOR_ENABLE_ERROR = 'CONSUMIDOR_ENABLE_ERROR';

export const CONSUMIDOR_DESATIVAR = 'CONSUMIDOR_DESATIVAR';
export const CONSUMIDOR_DESATIVAR_ERROR = 'CONSUMIDOR_DESATIVAR_ERROR';
export const CONSUMIDOR_DESATIVAR_SUCCESS = 'CONSUMIDOR_DESATIVAR_SUCCESS';

export const TELEFONE_DELETE = 'TELEFONE_DELETE';
export const TELEFONE_DELETE_SUCCESS = 'TELEFONE_DELETE_SUCCESS';
export const TELEFONE_DELETE_ERROR = 'TELEFONE_DELETE_ERROR';

export const TELEFONE_ADD = 'TELEFONE_ADD';
export const TELEFONE_ADD_SUCCESS = 'TELEFONE_ADD_SUCCESS';
export const TELEFONE_ADD_ERROR = 'TELEFONE_ADD_ERROR';

export const LOADING_SHOW = 'LOADING_SHOW';
export const LOADING_HIDE = 'LOADING_HIDE';

export const SNACKBAR_SUCCESS = 'SNACKBAR_SUCCESS';
export const SNACKBAR_ERROR = 'SNACKBAR_ERROR';
export const SNACKBAR_WARNING = 'SNACKBAR_WARNING';
export const SNACKBAR_CLEAR = 'SNACKBAR_CLEAR';

export const GESTAO_EMPRESA_SAVE = 'GESTAO_EMPRESA_SAVE';
export const GESTAO_CONSUMIDOR_SAVE = 'GESTAO_CONSUMIDOR_SAVE';
export const GESTAO_RECLAMACAO_SAVE = 'GESTAO_RECLAMACAO_SAVE';
export const GESTAO_LIMPAR = 'GESTAO_LIMPAR';

export const MENU_OPEN = 'MENU_OPEN';
export const MENU_CLOSE = 'MENU_CLOSE';

export const RECLAMACAO_FETCH = 'RECLAMACAO_FETCH';
export const RECLAMACAO_FETCH_SUCCESS = 'RECLAMACAO_FETCH_SUCCESS';
export const RECLAMACAO_FETCH_ERROR = 'RECLAMACAO_FETCH_ERROR';

export const RECLAMACAO_CADASTRAR = 'RECLAMACAO_CADASTRAR';
export const RECLAMACAO_CADASTRAR_ERROR = 'RECLAMACAO_CADASTRAR_ERROR';

export const CAPTCHA_ERROR = 'CAPTCHA_ERROR';
export const CAPTCHA_CLEAR = 'CAPTCHA_CLEAR';
