import {
  EMPRESA_READ_ONLY,
  EMPRESA_LOADING_SHOW,
  EMPRESA_FETCH_SUCCESS,
  EMPRESA_FETCH_ERROR,
  EMPRESA_LOADING_HIDE,
} from '~/store/actions/types';

export const INITIAL_STATE = {
  data: null,
  loading: false,
  readOnly: true,
  error: { message: '', hasError: false },
};

const EmpresaReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EMPRESA_READ_ONLY:
      return {
        ...state,
        readOnly: action.payload,
      };
    case EMPRESA_LOADING_SHOW:
      return {
        ...state,
        loading: true,
      };
    case EMPRESA_FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    case EMPRESA_FETCH_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: { message: action.payload.message, hasError: true },
      };
    case EMPRESA_LOADING_HIDE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default EmpresaReducer;
