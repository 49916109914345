import React from 'react';

import Routes from '~/routes';

import './App.css';

import backgroundImg from './assets/img/pattern.png';
import backgroundImg1 from './assets/img/pattern1.png';

const App = () => {
  //Limpar o storage quando o usuário fechar o navegador
  window.onbeforeunload = () => {
    sessionStorage.clear();
  };

  return (
    <div
      style={{
        minHeight: '100vh',
        background: `#EBF3F8 url(${backgroundImg}) 0% 0% no-repeat padding-box`,
        backgroundSize: '32%',
      }}
    >
      <div
        style={{
          minHeight: '100vh',
          background: `transparent url(${backgroundImg1}) 100% 100% no-repeat padding-box`,
          backgroundSize: '32%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Routes />
      </div>
    </div>

    // <KeycloakProvider
    //   keycloak={keycloak}
    //   initConfig={keycloakConfig}
    //   onEvent={onKeycloakEvent}
    //   onTokens={onKeycloakTokens}
    //   onError={onKeycloakError}
    //   LoadingComponent={<Loading/>}
    // >
    // </KeycloakProvider>
  );
};

export default App;
