import { GESTAO_CONSUMIDOR_SAVE, GESTAO_EMPRESA_SAVE, GESTAO_RECLAMACAO_SAVE, GESTAO_LIMPAR } from '../actions/types';

const INITIAL_STATE = { consumidor: null, empresa: null, reclamacao: null };

const RelatorioReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GESTAO_CONSUMIDOR_SAVE:
      return {
        consumidor: action.payload.data,
        empresa: null,
        reclamacao: null,
      };
    case GESTAO_EMPRESA_SAVE:
      return {
        consumidor: null,
        empresa: action.payload.data,
        reclamacao: null,
      };
    case GESTAO_RECLAMACAO_SAVE:
      return {
        consumidor: null,
        empresa: null,
        reclamacao: action.payload.data,
      };
    case GESTAO_LIMPAR: {
      return { consumidor: null, empresa: null, reclamacao: null };
    }
    default:
      return state;
  }
};

export default RelatorioReducer;
