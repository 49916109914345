import { SNACKBAR_SUCCESS, SNACKBAR_ERROR, SNACKBAR_WARNING, SNACKBAR_CLEAR } from '../actions/types';

export const INITIAL_STATE = {
  open: false,
  message: '',
  variant: 'warning',
};

const SnackbarReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SNACKBAR_SUCCESS:
      return {
        ...state,
        open: true,
        message: action.payload.message,
        variant: 'success',
      };
    case SNACKBAR_ERROR:
      return {
        ...state,
        open: true,
        message: action.payload.message,
        variant: 'error',
      };
    case SNACKBAR_WARNING:
      return {
        ...state,
        open: true,
        message: action.payload.message,
        variant: 'warning',
      };
    case SNACKBAR_CLEAR:
      return {
        open: false,
        message: state.message,
        variant: state.variant,
      };
    default:
      return state;
  }
};

export default SnackbarReducer;
