import { SNACKBAR_SUCCESS, SNACKBAR_ERROR, SNACKBAR_WARNING, SNACKBAR_CLEAR } from '../actions/types';

export const Success = params => dispatch => {
  dispatch({
    type: SNACKBAR_SUCCESS,
    payload: {
      message: params,
    },
  });
};

export const Error = params => dispatch => {
  dispatch({
    type: SNACKBAR_ERROR,
    payload: {
      message: params,
    },
  });
};

export const Warning = params => dispatch => {
  dispatch({
    type: SNACKBAR_WARNING,
    payload: {
      message: params,
    },
  });
};

export const clearSnackbar = () => {
  return dispatch => {
    dispatch({ type: SNACKBAR_CLEAR });
  };
};
