import { CAPTCHA_CLEAR, CAPTCHA_ERROR } from './types';

export const CaptchaError = () => dispatch => {
  dispatch({
    type: CAPTCHA_ERROR,
  });
};

export const CaptchaClear = () => dispatch => {
  dispatch({
    type: CAPTCHA_CLEAR,
  });
};
