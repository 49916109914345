import { applyMiddleware, createStore } from 'redux';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducers from './reducers';
import persistReducers from './persistReducers';

const composeEnhancers = composeWithDevTools({});

const store = createStore(persistReducers(rootReducers), composeEnhancers(applyMiddleware(thunk)));

const persistor = persistStore(store);

export { store, persistor };
