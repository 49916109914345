import React from 'react';
import { CircularProgress } from '@material-ui/core';
import LoadingOverlay from 'react-loading-overlay';

const DEFAULT_STYLES = {
  overlay: base => ({
    ...base,
    // background: 'transparente',
    background: 'rgba(0,0,0,0.1)',
    zIndex: 99999,
  }),
};

const Spinner = props => {
  return (
    <LoadingOverlay
      active={props.loading}
      styles={props.styles ? props.styles : DEFAULT_STYLES}
      spinner={<CircularProgress />}
    >
      {props.children}
    </LoadingOverlay>
  );
};

export default Spinner;
