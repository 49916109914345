import { GESTAO_CONSUMIDOR_SAVE, GESTAO_EMPRESA_SAVE, GESTAO_RECLAMACAO_SAVE, GESTAO_LIMPAR } from '../actions/types';

export const RelatorioConsumidorSave = params => dispatch => {
  dispatch({
    type: GESTAO_CONSUMIDOR_SAVE,
    payload: {
      data: params,
    },
  });
};

export const RelatorioEmpresaSave = params => dispatch => {
  dispatch({
    type: GESTAO_EMPRESA_SAVE,
    payload: {
      data: params,
    },
  });
};

export const RelatorioReclamacaoSave = params => dispatch => {
  dispatch({
    type: GESTAO_RECLAMACAO_SAVE,
    payload: {
      data: params,
    },
  });
};

export const RelatorioLimpar = () => dispatch => {
  dispatch({ type: GESTAO_LIMPAR });
};
