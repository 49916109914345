import { CAPTCHA_CLEAR, CAPTCHA_ERROR } from '../../store/actions/types';

export const INITIAL_STATE = {
  loginError: 0,
};

const CaptchaReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CAPTCHA_ERROR:
      return { loginError: state.loginError + 1 };
    case CAPTCHA_CLEAR:
      return { loginError: 0 };
    default:
      return state;
  }
};

export default CaptchaReducer;
