import {
    TELEFONE_DELETE,
    TELEFONE_DELETE_ERROR,
    TELEFONE_DELETE_SUCCESS,
    TELEFONE_ADD,
    TELEFONE_ADD_ERROR,
    TELEFONE_ADD_SUCCESS,
} from '../actions/types';

export const INITIAL_STATE = {
    telefones: [],
    loadingTelefone: false,
    message: '',
    snackbar: {
        success: true,
        open: false,
        message: '',
    },
    err: { message: '', hasError: false },
};


const TelefoneReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TELEFONE_DELETE:
            return {
                ...state,
                loadingTelefone: true
            };
        case TELEFONE_DELETE_SUCCESS:
            return {
                ...state,
                telefones: action.payload.data,
                snackbar: {
                    success: action.payload.success,
                    open: action.payload.open,
                    message: action.payload.message,
                },
                loadingTelefone: false,
            };
        case TELEFONE_DELETE_ERROR:
            return {
                ...state,
                telefones: [],
                snackbar: {
                    success: action.payload.success,
                    open: action.payload.open,
                    message: action.payload.message,
                },
                loadingTelefone: false,
                err: { message: action.payload.message, hasError: true },
            };
        case TELEFONE_ADD:
            return {
                ...state,
                loadingTelefone: true
            };
        case TELEFONE_ADD_SUCCESS:
            return {
                ...state,
                snackbar: {
                    success: action.payload.success,
                    open: action.payload.open,
                    message: action.payload.message,
                },
                telefones: action.payload.data,
                loadingTelefone: false,
            };
        case TELEFONE_ADD_ERROR:
            return {
                ...state,
                telefones: [],
                snackbar: {
                    success: action.payload.success,
                    open: action.payload.open,
                    message: action.payload.message,
                },
                loadingTelefone: false,
                err: { message: action.payload.message, hasError: true },
            };
        default:
            return state;
    }
}

export default TelefoneReducer