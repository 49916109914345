import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';
import isMobile from 'ismobilejs';

import { LogoutAction } from '../store/actions/AuthActions';
import { menuClose } from '../store/actions/MenuActions';

import { ReactComponent as UserIcon } from '../assets/svgs/user.svg';
import { ReactComponent as DashIcon } from '../assets/svgs/dash.svg';
import { ReactComponent as CadastroIcon } from '../assets/svgs/cadastro.svg';
import { ReactComponent as SenhaIcon } from '../assets/svgs/senha.svg';
import { ReactComponent as SairIcon } from '../assets/svgs/sair.svg';
import { ReactComponent as ConsumidorIcon } from '../assets/svgs/man.svg';
import { ReactComponent as EmpresaIcon } from '../assets/svgs/building.svg';
import { ReactComponent as ReclamacaoIcon } from '../assets/svgs/reclamac.svg';
import { RelatorioLimpar } from '../store/actions/RelatorioActions';

const drawerWidth = 200;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: '#003b6e',
    [theme.breakpoints.down('sm')]: {
      zIndex: theme.zIndex.drawer + 2,
    },
  },
  appBar: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    '&:hover': {
      width: !isMobile(window.navigator.userAgent).any && drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    '&:hover $divider': {
      width: '93%',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    '&:not(:hover) $divider': {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  divider: {
    backgroundColor: '#fff',
    width: drawerWidth - 5,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    margin: '0 auto',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    width: 60,
    [theme.breakpoints.down('sm')]: {
      width: 0,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  divSubItem: {
    backgroundColor: '#E6E6E6',
  },
  divSubItemText: {
    color: '#707070',
    whiteSpace: 'normal',
  },
  userNameNormal: {
    whiteSpace: 'normal',
  },
}));

const AppMenu = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { nome, isAuthenticated, authority } = useSelector(store => store.authReducer);
  const { isOpen } = useSelector(store => store.menuReducer);
  // const [open, setOpen] = useState(false);
  const [isAdministrador, setIsAdministrador] = useState(false);
  const [menuConsumidor, setMenuConsumidor] = useState(false);
  const [, setMenuEmpresa] = useState(false);
  const [openConsumidor, setOpenConsumidor] = useState(false);
  const [openEmpresa, setOpenEmpresa] = useState(false);
  const [openReclamacao, setOpenReclamacao] = useState(false);
  const { location } = props;

  const handleMenuConsumidor = () => {
    setOpenEmpresa(false);
    setOpenReclamacao(false);
    setOpenConsumidor(!openConsumidor);
    dispatch(RelatorioLimpar());
  };

  const handleMenuEmpresa = () => {
    setOpenConsumidor(false);
    setOpenReclamacao(false);
    setOpenEmpresa(!openEmpresa);
    dispatch(RelatorioLimpar());
  };

  const handleMenuReclamacao = () => {
    setOpenConsumidor(false);
    setOpenEmpresa(false);
    setOpenReclamacao(!openReclamacao);
    dispatch(RelatorioLimpar());
  };

  const closeMenu = () => {
    setOpenEmpresa(false);
    setOpenConsumidor(false);
    setOpenReclamacao(false);
    dispatch(menuClose());
  };

  const handleLogout = () => {
    dispatch(LogoutAction());
    menuClose(false);
    history.push('/');
  };

  const handleRoute = (url, parans) => {
    isMobile(window.navigator.userAgent).any && dispatch(menuClose());
    props.history.push({
      pathname: `${url}`,
      state: parans,
    });
  };

  useEffect(() => {
    if (location) {
      if (location.pathname.includes('consumidor') && !location.pathname.includes('administrador')) {
        setMenuEmpresa(false);
        setIsAdministrador(false);
        return setMenuConsumidor(true);
      }

      if (location.pathname.includes('administrador')) {
        return setIsAdministrador(true);
      }
      if (location.pathname.includes('empresa') && !location.pathname.includes('administrador')) {
        setMenuConsumidor(false);
        setIsAdministrador(false);
        return setMenuEmpresa(true);
      }
    }
  }, [location]);

  const renderMenuAdministrador = () => {
    return (
      <List style={{ paddingTop: 0 }} component="nav">
        <ListItem button key={1} onClick={handleMenuConsumidor}>
          <ListItemIcon>
            <ConsumidorIcon className="icon-white" />
          </ListItemIcon>
          <ListItemText primary={'Consumidor'} />
        </ListItem>
        <Collapse in={openConsumidor} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.divSubItem}>
            <ListItem
              button
              className={classes.nested}
              onClick={() => handleRoute(`/administrador/consumidor/manterconsumidor`)}
            >
              <ListItemText className={classes.divSubItemText} primary="Gestão Dados Consumidor" />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={() => handleRoute(`/administrador/consumidor/relatorio`)}
            >
              <ListItemText className={classes.divSubItemText} primary="Relatório Consumidor" />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={() => handleRoute(`/administrador/consumidor/numerosbloqueados`)}
            >
              <ListItemText
                className={classes.divSubItemText}
                style={{ whiteSpace: 'normal' }}
                primary="Relatório Números Bloqueados"
              />
            </ListItem>
          </List>
        </Collapse>
        <ListItem button key={2} onClick={handleMenuEmpresa}>
          <ListItemIcon>
            <EmpresaIcon className="icon-white" />
          </ListItemIcon>
          <ListItemText primary={'Empresa'} />
        </ListItem>
        <Collapse in={openEmpresa} timeout="auto" unmountOnExit className={classes.divSubItem}>
          <List component="div" disablePadding className={classes.divSubItem}>
            <ListItem button className={classes.nested} onClick={() => handleRoute(`/administrador/empresa/gestao`)}>
              <ListItemText className={classes.divSubItemText} primary="Gestão Dados Empresa" />
            </ListItem>
            <ListItem button className={classes.nested} onClick={() => handleRoute(`/administrador/empresa/relatorio`)}>
              <ListItemText className={classes.divSubItemText} primary="Relatório Empresa" />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={() => handleRoute(`/administrador/empresa/relatorioempresareclamacao`)}
            >
              <ListItemText className={classes.divSubItemText} primary="Relatório de Empresas Mais Reclamadas" />
            </ListItem>
          </List>
        </Collapse>
        <ListItem button key={3} onClick={handleMenuReclamacao}>
          <ListItemIcon>
            <ReclamacaoIcon className="icon-white" />
          </ListItemIcon>
          <ListItemText primary={'Reclamação'} />
        </ListItem>
        <Collapse in={openReclamacao} timeout="auto" unmountOnExit className={classes.divSubItem}>
          <List
            component="div"
            disablePadding
            className={classes.divSubItem}
            onClick={() => handleRoute(`/administrador/reclamacao/gestao`)}
          >
            <ListItem button className={classes.nested}>
              <ListItemText className={classes.divSubItemText} primary="Gestão Reclamações" />
            </ListItem>
          </List>
          <List
            component="div"
            disablePadding
            className={classes.divSubItem}
            onClick={() => handleRoute(`/administrador/reclamacao/relatoriolog`)}
          >
            <ListItem button className={classes.nested}>
              <ListItemText className={classes.divSubItemText} primary="Log de Alteração" />
            </ListItem>
          </List>
        </Collapse>
      </List>
    );
  };

  const renderMenuEmpresaConsumidor = () => {
    return (
      <List style={{ paddingTop: 0 }}>
        {menuConsumidor && (
          <ListItem
            button
            key={2}
            onClick={() => {
              handleRoute(`/${authority.toLowerCase()}`, { alterar: true });
            }}
          >
            <ListItemIcon>
              <CadastroIcon className="icon-white" />
            </ListItemIcon>
            <ListItemText primary={'Alterar dados'} />
          </ListItem>
        )}
        <ListItem button key={3} onClick={() => handleRoute(`/alterar-senha`)}>
          <ListItemIcon>
            <SenhaIcon className="icon-white" />
          </ListItemIcon>
          <ListItemText primary={'Alterar Senha'} />
        </ListItem>
        {menuConsumidor && (
          <ListItem
            button
            key={4}
            onClick={() => {
              handleRoute(`/consumidor/reclamacao`);
            }}
          >
            <ListItemIcon>
              <ReclamacaoIcon className="icon-white" />
            </ListItemIcon>
            <ListItemText primary={'Reclamação'} />
          </ListItem>
        )}
      </List>
    );
  };

  return (
    <>
      {isAuthenticated && (
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, classes.root, {
            [classes.drawerOpen]: isOpen,
            [classes.drawerClose]: !isOpen,
          })}
          classes={{
            paper: clsx(classes.drawer, classes.root, {
              [classes.drawerOpen]: isOpen,
              [classes.drawerClose]: !isOpen,
            }),
          }}
          open={isOpen}
          onMouseLeave={() => {
            !isMobile(window.navigator.userAgent).any && closeMenu();
          }}
        >
          <div className={classes.toolbar}>
            <IconButton
              onClick={() => {
                closeMenu();
              }}
            >
              <ChevronLeftIcon style={{ color: 'white' }} />
            </IconButton>
          </div>
          <List>
            <ListItem button key={1}>
              <ListItemIcon>
                <UserIcon className="icon-white" />
              </ListItemIcon>
              <ListItemText primary={nome} className={classes.userNameNormal} />
            </ListItem>
          </List>
          <Divider className={classes.divider} />
          <List style={{ paddingBottom: 0 }}>
            <ListItem
              button
              key={1}
              onClick={() => {
                closeMenu();
                return handleRoute(`/${authority.toLowerCase()}`);
              }}
            >
              <ListItemIcon>
                <DashIcon className="icon-dash" />
              </ListItemIcon>
              <ListItemText primary={'Home'} />
            </ListItem>
          </List>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100vh',
              justifyContent: ' space-between',
            }}
          >
            {isAdministrador ? renderMenuAdministrador() : renderMenuEmpresaConsumidor()}
            <List>
              <ListItem button key={'Sair'} onClick={handleLogout}>
                <ListItemIcon>
                  <SairIcon className="icon-white" />
                </ListItemIcon>
                <ListItemText primary={'Sair'} />
              </ListItem>
            </List>
          </div>
        </Drawer>
      )}
    </>
  );
};

export default withRouter(AppMenu);
