import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

import authReducer from './AuthReducer';
import userReducer from './UsersReducer';
import empresaReducer from './EmpresaReducer';
import consumidorReducer from './ConsumidorReducer';
import telefoneReducer from './TelefoneReducer';
import loadingReducer from './LoadingReducer';
import snackbarReducer from './SnackbarReducer';
import menuReducer from './MenuReducer';
import relatorioReducer from './RelatorioReducer';
import reclamacaoReducer from './ReclamacaoReducer';
import captchaReducer from './CaptchaReducer';

const appReducer = combineReducers({
  authReducer,
  userReducer,
  empresaReducer,
  consumidorReducer,
  telefoneReducer,
  loadingReducer,
  snackbarReducer,
  relatorioReducer,
  menuReducer,
  reclamacaoReducer,
  captchaReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'AUTH_LOGOUT') {
    storage.removeItem('persist:root');
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
