import {
  CONSUMIDOR_FETCH,
  CONSUMIDOR_FETCH_SUCCESS,
  CONSUMIDOR_FETCH_ERROR,
  CONSUMIDOR_SUBMIT,
  CONSUMIDOR_SUBMIT_SUCCESS,
  CONSUMIDOR_SUBMIT_ERROR,
  CONSUMIDOR_DISABLE,
  CONSUMIDOR_DISABLE_SUCCESS,
  CONSUMIDOR_DISABLE_ERROR,
  CONSUMIDOR_ENABLE,
  CONSUMIDOR_ENABLE_SUCCESS,
  CONSUMIDOR_ENABLE_ERROR,
  CONSUMIDOR_SIGNED,
  CONSUMIDOR_SIGNED_SUCCESS,
  CONSUMIDOR_SIGNED_ERROR,
} from '../actions/types';

export const INITIAL_STATE = {
  data: null,
  loading: false,
  error: { message: '', hasError: false },
};

const ConsumidorReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONSUMIDOR_FETCH:
      return {
        ...state,
        loading: true,
      };
    case CONSUMIDOR_FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        length: action.payload.length,
        loading: false,
      };
    case CONSUMIDOR_FETCH_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: { message: action.payload.message, hasError: true },
      };
    case CONSUMIDOR_SUBMIT:
      return {
        ...state,
        loadingSubmit: true,
      };
    case CONSUMIDOR_SUBMIT_SUCCESS:
      return {
        ...state,
        response: action.payload.response,
        data: action.payload.data,
        length: action.payload.length,
        snackbarConsumidor: action.payload.snackbarConsumidor,
        cadastro: true,
        loadingSubmit: false,
        error: { message: '', hasError: false },
      };
    case CONSUMIDOR_SUBMIT_ERROR:
      if (action.payload.params.id) {
        return {
          ...state,
          params: action.payload.params,
          response: action.payload.response,
          snackbarConsumidor: action.payload.snackbarConsumidor,
          loadingSubmit: false,
          error: { message: action.payload.message, hasError: true },
        };
      }
      return {
        ...state,
        data: null,
        params: action.payload.params,
        response: action.payload.response,
        snackbarConsumidor: action.payload.snackbarConsumidor,
        loadingSubmit: false,
        error: { message: action.payload.message, hasError: true },
      };
    case CONSUMIDOR_DISABLE:
      return {
        ...state,
        loadingSubmit: true,
      };
    case CONSUMIDOR_DISABLE_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        snackbarConsumidor: action.payload.snackbarConsumidor,
        loadingSubmit: false,
        error: { message: '', hasError: false },
      };
    case CONSUMIDOR_DISABLE_ERROR:
      return {
        ...state,
        loadingSubmit: false,
        snackbarConsumidor: action.payload.snackbarConsumidor,
        error: { message: action.payload.message, hasError: true },
      };
    case CONSUMIDOR_ENABLE:
      return {
        ...state,
        loadingSubmit: true,
      };
    case CONSUMIDOR_ENABLE_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        snackbarConsumidor: action.payload.snackbarConsumidor,
        loadingSubmit: false,
        error: { message: '', hasError: false },
      };
    case CONSUMIDOR_ENABLE_ERROR:
      return {
        ...state,
        data: null,
        loadingSubmit: false,
        snackbarConsumidor: action.payload.snackbarConsumidor,
        error: { message: action.payload.message, hasError: true },
      };
    case CONSUMIDOR_SIGNED:
      return {
        ...state,
        loadingSigned: true,
      };
    case CONSUMIDOR_SIGNED_SUCCESS:
      return {
        ...state,
        signed: action.payload.signed,
        loadingSigned: false,
        error: { message: '', hasError: false },
      };
    case CONSUMIDOR_SIGNED_ERROR:
      return {
        ...state,
        loadingSigned: false,
        error: { message: action.payload.message, hasError: true },
      };
    default:
      return state;
  }
};

export default ConsumidorReducer;
