import { RECLAMACAO_FETCH, RECLAMACAO_FETCH_SUCCESS, RECLAMACAO_FETCH_ERROR } from '../actions/types';

export const INITIAL_STATE = {
  reclamacoes: null,
  loadingReclamacao: false,
  erroReclamacao: { message: '', hasError: false },
};

const ReclamacaoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RECLAMACAO_FETCH:
      return {
        ...state,
        loadingReclamacao: true,
      };
    case RECLAMACAO_FETCH_SUCCESS:
      return {
        ...state,
        reclamacoes: action.payload.data,
        length: action.payload.length,
        loadingReclamacao: false,
      };
    case RECLAMACAO_FETCH_ERROR:
      return {
        ...state,
        loadingReclamacao: false,
        erroReclamacao: { message: action.payload.message, hasError: true },
      };
    default:
      return state;
  }
};

export default ReclamacaoReducer;
